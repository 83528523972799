<template>
  <div :class="classContainer">
    <d-body-top-bar
        :title="$t('views.playground.config')"
        :is-icons-content="false"
    >
      <div slot="tree">
        <label v-if="mobileView" class="inactive-tree mr-1">
          {{ $t('views.playground.your-activities-area') }} /
        </label>
        <label @click="$router.push('/playground')" class="inactive-tree link-area pointer">
          {{ $t('views.playground.all-area') }} /
        </label>
        <label class="bg-doinsport-primary ml-1"> {{ topBarTitle }} </label>
      </div>
      <div slot="icons">
        <steps
            :step="4"
            @on:step-0:selected="$router.push({name: 'playground_update', params:{ id: $route.params.id }})"
            @on:step-1:selected="$router.push({name: 'playground_config-step1', params: { id: $route.params.id }})"
            @on:step-2:selected="$router.push({name: 'playground_config-step2', params: { id: $route.params.id }})"
            @on:step-3:selected="$router.push({name: 'playground_config-step3', params: { id: $route.params.id }})"
        />
      </div>
    </d-body-top-bar>
    <div class="playground-creation-container">
      <description
          :selectedBlocks="selectedBlocks"
          @on:step-4-save="saveConfiguration = !saveConfiguration"
      />
      <b-row class="mt-2">
        <b-col cols="6">
          <selected-block-prices
              :is-playground-prices="true"
              @on:block-price:selected="onBlockPriceSelected"
          />
        </b-col>
        <b-col cols="6">
          <selected-timetables
              :selected-block-price="blockPriceId"
              :save-configuration="saveConfiguration"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    selectedBlocks: [],
    blockPriceId: null,
    saveConfiguration: false
  }),
  components: {
    Description: () => import('./Description'),
    SelectedBlockPrices: () => import('./SelectedBlockPrices'),
    SelectedTimetables: () => import('./SelectedTimetables'),
    Steps: () => import('@views/playground/steps/Menus')
  },
  props: {
    classContainer: {
      type: String,
      default: ''
    },
  },
  computed: {
    topBarTitle() {
      if (this.$route.name === 'playground_new') {
        return this.$t('views.playground.config');
      } else {
        return this.$t('views.playground.config');
      }
    },
    mobileView() {
      return this.$store.getters['layout/getInnerWidth'] >= 991;
    }
  },
  methods: {
    onBlockPriceSelected(blockPriceId) {
      this.blockPriceId = blockPriceId;
    }
  },
  created() {
    this.blockPriceId = null;
  }
}
</script>
<style scoped>
.playground-creation-container {
  background-color: #F9FAFA;
}
</style>
